import { uniqueId } from 'lodash';
import { useCallback } from 'react';

import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export const NETWORK_ERROR_MESSAGE = '네트워크 연결에 실패하였습니다. 앱을 종료 후 다시 시도해주세요.';

const alertAtom = atom({
  key: `webviewAlertAtom/${uniqueId()}`,
  default: {
    visible: false,
    message: NETWORK_ERROR_MESSAGE,
    title: '',
    callback: () => {},
  },
});

export default function useAlert() {
  const state = useRecoilValue(alertAtom);
  const setState = useSetRecoilState(alertAtom);

  const openAlert = useCallback(
    (message = NETWORK_ERROR_MESSAGE, title = '', callback?: () => void) => {
      setState({
        visible: true,
        message,
        title,
        callback: callback ?? (() => {}),
      });
    },
    [setState],
  );

  const closeAlert = useCallback(() => {
    state.callback();
    setState({ ...state, visible: false });
  }, [state, setState]);

  return {
    visible: state.visible,
    message: state.message,
    title: state.title,
    openAlert,
    closeAlert,
  };
}
