import { AppContext, AppInitialProps } from 'next/app';
import { AppProps } from 'next/dist/next-server/lib/router/router';
import { useRouter } from 'next/router';
import { createGlobalStyle } from 'styled-components';
import { isMobile } from '../common/utils/isMobile';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { Provider as JotaiProvider } from 'jotai';
import { getCookie, setCookie } from 'cookies-next';
import { NextPageContext } from 'next';
import { isEmpty } from '../common/utils/isEmpty';
import { getPhase } from '../common/utils/phase';
import { PopupCommon } from 'client-common';
import useAlert from '../components/hooks/useAlert';
import { onGaNextInitialProps } from '../components/GoogleAnanlytics';
import { useGaPageViewRouter } from '../components/GoogleAnanlytics';
import Toast from '../components/Toast';
import 'design-system-web/dist/style.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  },
});

function ErrorCallbackComponent() {
  const { message: alertMessage, title: alertTitle, visible, closeAlert } = useAlert();
  return <PopupCommon message={alertMessage} title={alertTitle} visible={visible} onClick={closeAlert} />;
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    storePath();
  }, [router.asPath]);

  useGaPageViewRouter();

  const storePath = () => {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    let prevPath: string = storage.getItem('currentPath') || '';
    storage.setItem('prevPath', prevPath);
    storage.setItem('currentPath', globalThis.location.pathname);
  };
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <JotaiProvider>
            <GlobalStyle />
            <div className={'wrap-loading'} id={'wrap-loading'}>
              &nbsp;
            </div>
            <Component {...pageProps} />
            <ErrorCallbackComponent />
            <Toast />
          </JotaiProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </>
  );
}

export const domainHash: { [key: string]: string } = {
  development: '192.168.0.4',
  alpha: '.cleaninglab.co.kr',
  production: '.cleaninglab.co.kr',
};

const setCookies = (ctx: NextPageContext, pairs: Object) => {
  Object.entries(pairs).forEach(([key, value]) => {
    if (!isEmpty(value)) {
      setCookie(key, value, {
        req: ctx.req,
        res: ctx.res,
        domain: domainHash[getPhase()],
      });
    }
  });
};

export type AppInfo = {
  app_version: string;
  auth_token: string;
  os_version: string;
  setting_revision: string;
  os: string;
};

MyApp.getInitialProps = async ({ Component, ctx }: AppContext): Promise<AppInitialProps> => {
  onGaNextInitialProps(ctx);
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const authToken = ctx.req?.headers['auth-token'] || ctx.query?.temp_str || getCookie('auth-token', { req: ctx.req });
  const os = ctx.req?.headers.os || ctx.query?.os || getCookie('os', { req: ctx.req });
  const appVersion =
    ctx.req?.headers['app-version'] || ctx.query?.app_version || getCookie('app-version', { req: ctx.req });
  const osVersion =
    ctx.req?.headers['os-version'] || ctx.query?.os_version || getCookie('os-version', { req: ctx.req });
  const settingRevision =
    ctx.req?.headers['setting-revision'] ||
    ctx.query?.setting_revision ||
    getCookie('setting-revision', { req: ctx.req });

  setCookies(ctx, {
    token: authToken, // front server
    'auth-token': authToken, // api server
    os,
    'app-version': appVersion,
    'os-version': osVersion,
    'setting-revision': settingRevision,
  });

  return {
    pageProps: {
      ...pageProps,
      mobile: isMobile(ctx.req?.headers['user-agent']),
      app_version: appVersion,
      auth_token: authToken,
      os_version: osVersion,
      setting_revision: settingRevision,
      os: os,
    },
  };
};

// @ts-ignore
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .wrap-loading { /*화면 전체를 어둡게 합니다.*/
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2); /*not in ie */
    filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr='#20000000', endColorstr='#20000000'); /* ie */
    z-index: 999999999999;
    display: none;
  }

  .wrap-loading div { /*로딩 이미지*/
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -64px;
    margin-top: -64px;
  }

  .wrap-loading-show { /*화면 전체를 어둡게 합니다.*/
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.35); /*not in ie */
    filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr='#20000000', endColorstr='#20000000'); /* ie */
    z-index: 999999999999;
    display: inherit;
  }

  .wrap-loading-show div { /*로딩 이미지*/

  }
`;

// @ts-ignore
export default MyApp;
