import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue, useSetAtom } from 'jotai';
import styled from 'styled-components';
import { toastAtom, toastFullAtom } from '../atoms/toastAtom';

// interface Props {
//   toast: string;
//   setToast: React.Dispatch<React.SetStateAction<string>>;
//   bottom?: number;
//   seconds?: number;
// }

export default function Toast() {
  const { toast, bottom, seconds, maxWidth } = useAtomValue(toastFullAtom);
  const setToast = useSetAtom(toastAtom);

  useEffect(() => {
    if (toast === '') return;
    const timer = setTimeout(() => setToast(''), seconds * 1000);
    return () => clearTimeout(timer);
  }, [toast]);

  return (
    <AnimatePresence>
      {toast && (
        <FixdConatiner
          $bottom={bottom}
          style={{ x: '-50%' }}
          initial={{ y: bottom, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: bottom, opacity: 0 }}
          transition={{ y: { duration: 0.3 }, opacity: { duration: 0.25 } }}
          $maxWidth={maxWidth}
        >
          <Wrapper>{toast}</Wrapper>
        </FixdConatiner>
      )}
    </AnimatePresence>
  );
}

const FixdConatiner = styled(motion.div)<{ $bottom: number; $maxWidth: number }>`
  position: fixed;
  width: 100%;
  /* max-width: 520px; */
  bottom: 0;
  left: 50%;
  padding: 0 24px;
  bottom: ${({ $bottom }) => $bottom}px;
  transform: translateX(-50%);
  max-width: ${({ $maxWidth }) => $maxWidth}px;
`;
/* max-width: ${theme.containerMaxWidth}; */
// z-index: ${theme.modalZIndex + 1};

const Wrapper = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 140%;
  padding: 14px 16px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  color: #fff;
  white-space: pre-line;
  user-select: none;
  /* width: fit-content; */
`;
// ${textCss('B3_R')};
