import { atom } from 'jotai';

interface ToastProps {
  toast: string;
  bottom: number;
  seconds: number;
  maxWidth: number;
}

export const TOAST_BOTTOM = {
  withButton: 88,
  noButton: 48,
} as const;

export const DEFAULT_MAX_WIDTH = 520;

export const toastFullAtom = atom({
  toast: '',
  bottom: TOAST_BOTTOM.noButton,
  seconds: 1.5,
  maxWidth: DEFAULT_MAX_WIDTH,
} as ToastProps);

export const toastAtom = atom(
  (get) => get(toastFullAtom).toast,
  (get, set, nextToast: string) => {
    set(toastFullAtom, { ...get(toastFullAtom), toast: nextToast });
  },
);

// export const useToast = (toast: string, optional?: { bottom?: number; seconds?: number }) => {
//   const setToast = useSetAtom(toastAtom);
//   return setToast((prev) => _.mergeWith({}, prev, { toast, ...optional }, (a, b) => (!b ? a : undefined)));
// };

// export default function useToast({ toast, bottom = 88, seconds = 1.5 }: ToastProps) {
//   const setAtom = useSetAtom(ToastAtom);
//   return setAtom;
// }
